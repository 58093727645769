<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Using standard reduction potentials
        <a
          href="https://openstax.org/books/chemistry-2e/pages/l-standard-electrode-half-cell-potentials"
          target="_blank"
          rel="noopener noreferrer"
        >
          available here,</a
        >
        calculate the standard cell potential (or emf) for the following reaction:
      </p>

      <p class="mb-5 pl-14">
        <chemical-latex :content="reaction" />
      </p>

      <p class="mb-2">
        a) Start by determining the standard reduction potential for the
        <b>anode</b>
      </p>

      <calculation-input
        v-model="inputs.Eanode"
        class="mb-5"
        prepend-text="$\text{E}^\circ_\text{anode}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Then, determine the standard reduction potential for the
        <b>cathode</b>.
      </p>

      <calculation-input
        v-model="inputs.Ecathode"
        class="mb-5"
        prepend-text="$\text{E}^\circ_\text{cathode}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Finally, calculate the standard cell potential for the cell:</p>

      <calculation-input
        v-model="inputs.Ecell"
        prepend-text="$\text{E}^\circ_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question425',
  components: {
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Eanode: null,
        Ecathode: null,
        Ecell: null,
      },
    };
  },
  computed: {
    reactionNumber() {
      return this.taskState.getValueBySymbol('reactionNumber').content;
    },
    reaction() {
      if (this.reactionNumber.value === 1) {
        return 'Cl2(g) + 2I-(aq) -> 2Cl-(aq) + I2(s)';
      } else if (this.reactionNumber.value === 2) {
        return 'Hg^2+(aq) + 2Fe^2+(aq) -> Hg(l) + 2Fe^3+(aq)';
      } else {
        return 'Ca(s) + Mn^2+(aq) -> Ca^2+(aq) + Mn(s)';
      }
    },
  },
};
</script>
